import { reactive, readonly, watch } from "vue";
// import { load, save } from '@/store/persist';
import tweets from '@/store/tweets';

const state = reactive({
});

watch(state, () => {
  onUpdate();
});

const init = () => {
    tweets.init();

    // load(state.userId, SCOPE);
}

const onUpdate = () => {
  // save(state, state.userId, SCOPE);
}

export default { 
  state: readonly(state), 
  init,
  tweets,
};