import axios from '@/services/axios';

class TwitterAPI {
    static async fetchTweets(startDateTime, endDateTime, hashtag, nextToken) {
        const response = await axios({
            method: 'POST',
            url: '/api/tweets',
            responseType: 'json',
            data: {
                startDateTime: startDateTime.toISOString(),
                endDateTime: endDateTime.toISOString(),
                hashtag,
                nextToken,
            }
          });
      
          return response.data;
    }
}

export default TwitterAPI;