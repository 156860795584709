import { reactive, readonly, watch } from "vue";
import TwitterAPI from '@/services/TwitterAPI';
// import { load, save } from '@/store/persist';

const state = reactive({
  configured: false,
  startDateTime: null,
  startTime: null,
  hashtag: '',

  tweetBlocks: [],

  currentDateTime: null,
  lastLoadDateTime: null,

  timeTrackerId: null,

  fetchingTweets: false,
});

watch(state, () => {
  onUpdate();
});

window.x = state;

const onUpdate = () => {
  // save(state, state.userId, SCOPE);
}

const startTimeshift = (startDate, startTime, hashtag) => {
  resetTimeshift();

  state.startDateTime = new Date(`${startDate} ${startTime}`);
  state.hashtag = hashtag;

  _startCurrentTimeTracker(state.startDateTime);

  fetchTweets();
  
  state.lastLoadDateTime = new Date();

  state.configured = true;
}

const _startCurrentTimeTracker = (startDateTime) => {
  state.currentDateTime = new Date(startDateTime);

  _incrementCurrentDateTime(); // 

  state.timeTrackerId = setInterval(() => {
    _incrementCurrentDateTime();
  }, 1000);
}

const _stopCurrentTimeTracker = () => {
  clearInterval(state.timeTrackerId);

  state.timeTrackerId = null;
}

const _incrementCurrentDateTime = () => {
  state.currentDateTime.setSeconds(state.currentDateTime.getSeconds() + 1);

  // create a new date so any components bound to it re-evaluate
  state.currentDateTime = new Date(state.currentDateTime);
}

const fetchTweets = async (nextToken) => {
  state.fetchingTweets = true;

  const tweets = await TwitterAPI.fetchTweets(state.startDateTime, state.currentDateTime, state.hashtag, nextToken);

  state.fetchingTweets = false;

  state.tweetBlocks.unshift(tweets);

  return state.tweetBlocks;
}

const resetTimeshift = () => {
  _stopCurrentTimeTracker();

  state.startDateTime = null;
  state.startTime = null;
  state.hashtag = '';

  state.tweetBlocks = [];

  state.currentDateTime = null;
  state.lastLoadDateTime = null;

  state.timeTrackerId = null;

  state.configured = false;
}

const init = () => {
  // Object.assign(state, load(userId, SCOPE));
};

export default {
    state: readonly(state),
    init,
    startTimeshift,
    fetchTweets,
};